import Lenis from '@studio-freight/lenis'
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import CSSPlugin from 'gsap/CSSPlugin';
gsap.registerPlugin(ScrollTrigger, CSSPlugin);


import Swiper from 'swiper';
import { Autoplay, Pagination, Scrollbar, Navigation, EffectFade } from 'swiper/modules';
Swiper.use([Autoplay, Pagination, Scrollbar, Navigation, EffectFade]);

//loading
import { headerBtn } from './module/common/headerBtn';

//anchor link
import { anchorLink } from './module/common/anchorLink';

const setScrollbarWidth = () => {
	const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
	// カスタムプロパティの値を更新する
	document.documentElement.style.setProperty('--scrollbar', `${scrollbarWidth}px`);
};

setScrollbarWidth();


const loadAnim = function () {
	const tl = gsap.timeline({});


	tl.to(
		heroBg,
		{
			rotate: 0,
			scale: 1,
			opacity: 1,
			duration: 1,
			ease: "power3.out",
		},
		"-=.35"
	);
	/*tl.to(
		heroBg,
		{
			rotate: 0,
			scale: 1,
			opacity: 1,
			duration: 1,
			ease: "power3.out",
		},
		"-=.35"
	);
	tl.to(
		heroLogo,
		{
			'--clip3': 0,
			duration: .44,
			ease: "expo.out",
		},
		"-=.4"
	);
	tl.to(
		heroLogoInner,
		{
			'--wit': 0,
			duration: .44,
			ease: "expo.out",
		},
		"-=.3"

	);

	tl.to(
		heroText,
		{
			'--clip': '0',
			duration: .6,
			ease: "expo.out",
		}, "-=.3"

	);

	tl.to(
		heroText.children,
		{
			backgroundSize: '100% 100%',
			duration: .6,
			ease: "expo.out",
		}, "-=.4"

	);
	tl.to(
		heroNews,
		{
			translateY: 0,
			opacity: 1,
			duration: .8,
			ease: "expo.out",
			delay: .2,
		}, '-=.3',

	);
	tl.to(
		headerBtn,
		{
			scale: 1,
			opacity: 1,
			duration: .8,
			ease: "expo.out",
		}, '<',

	);*/
}

const animBlock = function () {
	gsap.utils.toArray(".js--anim__block").forEach((target) => {

		const items = target.querySelectorAll(".js--anim");

		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: target,
				start: "top bottom-=30%",
			},
		});
		items.forEach(item => {
			tl.to(
				item,
				{
					y: '0',
					opacity: 1,
					duration: 1,
					ease: "expo.out",
				}, "<.1",

			);

		});
	});
}
const animFaq = function () {
	gsap.utils.toArray(".faq__item").forEach((target) => {

		const btn = target.querySelector("dt");
		const dom = target.querySelector("dd");

		const tl = gsap.timeline({
		});

		let state2 = false;

		btn.addEventListener("click", function () {
			if (state2 == false) {
				target.classList.add("active");
				tl.to(
					dom,
					{
						autoAlpha: 1,
						display: "block",
						duration: 0,
						ease: "expo.out",
					},

				);
				state2 = true;
			} else {
				target.classList.remove("active");
				tl.to(
					dom,
					{
						display: "none",
						autoAlpha: 0,
						duration: 0,
						ease: "expo.out",
					},

				);
				state2 = false;
			}
		});
	});
}


window.addEventListener("pageshow", function () {
	const lenis = new Lenis({
		lerp: 0.3, // 慣性の強さ
		duration: .8, // スクロールアニメーションの時間
		easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
		smooth: true,
		smoothTouch: false,
	});

	function raf(time) {
		lenis.raf(time);
		ScrollTrigger.update();
		requestAnimationFrame(raf);
	}

	requestAnimationFrame(raf);


	const slider1 = new Swiper('.hero__slide .swiper', {
		loop: true,
		speed: 2000,
		autoplay: { // 自動再生
			delay: 3000, disableOnInteraction: false,
		},
		effect: 'fade',
		fadeEffect: {
			crossFade: true
		},
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
		},
	});
	const slider2 = new Swiper('.photo__slide .swiper', {
		loop: true,
		//speed: 2000,
		autoplay: { // 自動再生
			//delay: 3000,disableOnInteraction: false,
		},
		spaceBetween: 16,
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev"
		}
	});

	const faqBtn = document.querySelectorAll('.faq-item-target dt');
	faqBtn.forEach(btn => {
		btn.addEventListener('click', e => {
			if (btn.parentNode.classList.contains('active')) {
				btn.parentNode.classList.remove("active");
			} else {
				btn.parentNode.classList.add("active");
			}
		});
	});

	//lenis.scrollTo("html", 'duration:0');

	const btn = document.querySelector('.header__btn');
	const menu_target = document.querySelector('.header__nav');
	const menu_overlay = document.querySelector('.header__overlay');




	let state = false;

	btn.addEventListener("click", function () {
		let scrollbarWidth = window.innerWidth - document.body.clientWidth;
		const vh = window.innerHeight;
		const windowHeight = document.documentElement.clientHeight;
		const header = document.querySelector('.header');
		const headerHeight = header.offsetHeight;

		if (state == false) {
			this.classList.add('active');
			//html.classList.add('fixed');
			menu_target.classList.add('active');
			menu_overlay.classList.add('active');
			//document.querySelector('body').classList.add('fixed');
			//btn.style.marginRight = scrollbarWidth + 'px';
			//document.querySelector('body').style.marginRight = scrollbarWidth + 'px';
			state = true;
		} else {
			this.classList.remove('active');
			//html.classList.remove('fixed');
			menu_overlay.classList.remove('active');
			menu_target.classList.remove('active');
			//document.querySelector('body').classList.remove('fixed');
			//btn.style.marginRight = '0px';
			//document.querySelector('body').style.marginRight = '0px';
			state = false;
		}
	});

	//アンカーリンク
	const anchorLinks = document.querySelectorAll('a[href^="#"]');
	const anchorLinksArr = Array.prototype.slice.call(anchorLinks);
	const header = document.querySelector('.header');
	const anchorHash = location.hash;

	//ハッシュ値でのアンカーリンク
	anchorLinksArr.forEach(link => {
		link.addEventListener('click', e => {
			e.preventDefault();
			const targetId = link.hash;
			const targetElement = document.querySelector(targetId);
			const targetOffsetTop = window.pageYOffset + targetElement.getBoundingClientRect().top;
			const header = document.querySelector('.header');
			const headerHeight = header.offsetHeight;
			//const totalScrollAmount = targetOffsetTop - headerHeight;
			const totalScrollAmount = targetOffsetTop - headerHeight;
			window.scrollTo({
				top: totalScrollAmount,
				behavior: "smooth"
			});

			btn.classList.remove('active');
			//html.classList.remove('fixed');
			menu_overlay.classList.remove('active');
			menu_target.classList.remove('active');
			//document.querySelector('body').classList.remove('fixed');
			//btn.style.marginRight = '0px';
			//document.querySelector('body').style.marginRight = '0px';
			state = false;
		});
	});

	if (anchorHash) {
		window.scrollTo({
			top: 0,
			behavior: "instant"
		});
		const targetElement = document.querySelector(anchorHash);
		const targetOffsetTop = window.pageYOffset + targetElement.getBoundingClientRect().top;
		const headerHeight = 0;
		const totalScrollAmount = targetOffsetTop - headerHeight;
		//const totalScrollAmount = targetOffsetTop - 40;

		var fn = function () {
			window.scrollTo({
				top: totalScrollAmount,
				behavior: "smooth"
			});
		};
		var tm = 500;
		setTimeout(fn, tm);
	}

	//animBlock();
	//animFaq();
	/*loadAnim();
	heroScroll();
	introText();
	scrollingText();
	aboutScroll();
	aboutScroll2();
	animBlock();
	parallaxImg();
	footerScroll();*/
	ScrollTrigger.refresh;



	const slider3 = new Swiper('.cemetary__slide01 .swiper', {
		loop: true,
		speed: 800,
	});

	const thumb01 = document.querySelectorAll(".thumb__list1 li");

	thumb01.forEach((link, index) => {
		link.addEventListener('click', e => {
			slider3.slideTo(index);
		});
	});


	const slider4 = new Swiper('.cemetary__slide02 .swiper', {
		loop: true,
		//speed: 2000,
	});

	const thumb02 = document.querySelectorAll(".thumb__list2 li");

	thumb02.forEach((link, index) => {
		link.addEventListener('click', e => {
			slider4.slideTo(index);
		});
	});


	const slider5 = new Swiper('.cemetary__slide03 .swiper', {
		loop: true,
		//speed: 2000,
	});

	const thumb03 = document.querySelectorAll(".thumb__list3 li");

	thumb03.forEach((link, index) => {
		link.addEventListener('click', e => {
			slider5.slideTo(index);
		});
	});


	const tabBtn = document.querySelectorAll(".lower__cemetary__box__tab li button");
	const tabDom = document.querySelectorAll(".lower__cemetary__box__inner");
	const tabOuter = document.querySelector(".lower__cemetary__box");

	tabBtn.forEach((link, index) => {
		link.addEventListener('click', e => {
			tabBtn.forEach((link2) => {
				link2.classList.remove('active');
			});
			tabDom.forEach((link2) => {
				link2.classList.remove('active');
			});

			console.log(index);

			link.classList.add('active');
			tabDom[index].classList.add('active');
		});
	});

	const headings = document.querySelectorAll('main');

	const options = {
		root: null,
		rootMargin: "0px 0px -98% 0px",
		threshold: 0
	};

	const observer = new IntersectionObserver(showElements, options);


	headings.forEach(heading => {
		observer.observe(heading);
	});

	function showElements(entries) {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				//gsap.to(entry.target, .7, { opacity: 1, y: 0, ease: 'power1.out' });
				document.querySelector('.header').classList.add('show');
			}else{
				document.querySelector('.header').classList.remove('show');
			}
		});
	};



});
window.addEventListener("resize", function () {
	setScrollbarWidth();
});
